import React from "react"
import { Link } from "gatsby"
import "./layout.css"
import Navigation from "../components/navigation"
import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = { addClass: false }
  }

  toggle() {
    this.setState({ addClass: !this.state.addClass })
  }

  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header
    let footer
    let headingClass

    if (location.pathname === rootPath) {
      headingClass = "home"
    }
    let boxClass = ["hidden"]
    if (this.state.addClass) {
      boxClass.push("show")
      document.getElementById("sidenav").style.width = "100%"
    }

    header = (
      <div className={"header " + headingClass}>
        <div className="col-12 col-md-6  col-lg-6">
          <h1>
            <Link to={"/"}>{title}</Link>
          </h1>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <Navigation />
          <ul id="sidenav" className={boxClass.join(" ")}>
            <li>
              <Link to={"/articles"}>Articles</Link>
            </li>
            <li>
              <Link to={"/blogs"}>Blogs</Link>
            </li>
            <li>
              <Link to={"/stories"}>Stories</Link>
            </li>
            <li>
              <Link to={"/locations"}>Locations</Link>
            </li>
            <li>
              <Link to={"/instagram"}>Instagram</Link>
            </li>
          </ul>
        </div>
      </div>
    )

    footer = (
      <div className="footer">
        <div className="col-12">
          <p>
            Created / Maintained by{" "}
            <a href="https://mattbayliss.co.uk">MattBayliss.co.uk</a>
          </p>
        </div>
      </div>
    )

    return (
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: rhythm(24),
          padding: `${rhythm(1)} ${rhythm(3 / 4)}`,
        }}
      >
        <span
          id="togglemenu"
          className={boxClass.join(" ")}
          onClick={this.toggle.bind(this)}
        >
          &#9776;
        </span>
        {header}
        <div className="content">{children}</div>
        {footer}
      </div>
    )
  }
}

export default Layout

export const pageQuery = `
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
