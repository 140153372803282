import React from "react"
import Link from "gatsby-link"
import "./navigation.css"

function Navigation() {
  return (
    <>
      <ul className="navigation">
        <li>
          <Link to={"/articles"}>Articles</Link>
        </li>
        <li>
          <Link to={"/blogs"}>Blogs</Link>
        </li>
        <li>
          <Link to={"/stories"}>Stories</Link>
        </li>
        <li>
          <Link to={"/locations"}>Locations</Link>
        </li>
        <li>
          <Link to={"https://www.instagram.com/serith/"}>Instagram</Link>
        </li>
      </ul>
    </>
  )
}

export default Navigation
