import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = () => ({

'.article .type, .blog .type, .story .type': {
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '6rem',
    textAlign: 'center',
    textTransform: 'capitalize',
    padding: '7px 0px',
    background: '#007acc',
    color: 'white',
    borderRadius: '0px 0px 0px 6px',
},

'ul, ol': {
  marginLeft: '1.75rem',
},

'blockquote': {
  marginLeft: '0.75rem',
},

'@media only screen and (max-width: 480px)': {
  'blockquote': {
    marginLeft: '0.75rem',
  }
}

})

delete Wordpress2016.googleFonts

const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
